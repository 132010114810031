import { Component, OnInit, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { PriceRatesService } from '../../services/price-rates.service';

@Component({
  selector: 'ngx-price-rates',
  templateUrl: './price-rates.component.html',
  styleUrls: ['./price-rates.component.scss']
})
export class PriceRatesComponent implements OnInit {

  resultDialogSubscription;
  dialogRef
  serviceSubscription;
  @ViewChild('resultDialog') resultDialog;

  constructor(private dialogService: NbDialogService, private service: PriceRatesService) { }

  open() {
    this.dialogRef = this.dialogService.open(this.resultDialog, { context: '' });
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.serviceSubscription = this.service.serviceSubject
      .subscribe(action => {
        switch (action) {
          case "open":
            this.open()
            break;
          case "close":
            this.close()
          default:
            break;
        }
      })
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.serviceSubscription.unsubscribe();
  }

}
