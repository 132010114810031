<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><img style="height: 29px;" src="https://business.sworld.co.uk/business/image/export_logo_withBusinessTag.png"></a>
  </div>
  <!-- <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select> -->
</div>

<div class="header-container">
  <nb-actions size="small">

    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
  <nb-action id="price-rates-action">
    <button (click)="onPriceRatesButtonClick()" nbButton>{{'PRICE_RATES.BUTTON_OPEN' | translate}}</button>
  </nb-action>
  <nb-action>
    <nb-select (selectedChange)="onLangSelectChange($event)" [selected]="languageService.translate.currentLang">
      <nb-option value="it">Italiano</nb-option>
      <nb-option value="en">English</nb-option>
      <nb-option value="de">Deutsche</nb-option>
    </nb-select>
  </nb-action>
    <nb-action class="control-item">
      <nb-user nbContextMenuTag="headerProfileContextMenu" [picture]="generalUser?.main_photo" [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="generalUserName"></nb-user>
    </nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user 
               id="business-profile-avatar"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.icon">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
