import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ResultDialogService {

  currentLangDictionary;
  languageSubscription;

  serviceSubject = new Subject();

  constructor(private languageService: TranslateService) {
    this.languageService.getTranslation(this.languageService.currentLang)
      .subscribe(dictionary => this.currentLangDictionary = dictionary);
    this.languageSubscription = this.languageService.onLangChange
      .subscribe(lang => {
        this.languageService.getTranslation(lang.lang)
          .subscribe(dictionary => this.currentLangDictionary = dictionary);
      })
  }

  translateText(text, langDictionaryKey) {
    if (langDictionaryKey == '')
      return text;
    return this.currentLangDictionary['RESPONSE_SERVICE_MESSAGES'][langDictionaryKey];
  }

  success(text, langDictionaryKey = '') {
    this.serviceSubject.next({ type: "success", message: this.translateText(text, langDictionaryKey) });
  }

  danger(text, langDictionaryKey = '', extraText = '') {
    this.serviceSubject.next({ type: "danger", message: this.translateText(text, langDictionaryKey) + extraText });
  }

  info(text, langDictionaryKey = '') {
    this.serviceSubject.next({ type: "info", message: this.translateText(text, langDictionaryKey) });
  }

}
