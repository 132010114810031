import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MyTranslateService {

  constructor(public translate: TranslateService) { 
    translate.setDefaultLang('it');
    translate.use('it');
  }
}
