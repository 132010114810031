import { Component, OnInit, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ImageZoomService } from '../../services/image-zoom.service';

@Component({
  selector: 'ngx-image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss']
})
export class ImageZoomComponent implements OnInit {

  dialogRef
  serviceSubscription;
  @ViewChild('imageZoom') resultDialog;

  constructor(private dialogService: NbDialogService, private service: ImageZoomService) { }

  open(imgUrl) {
    this.dialogRef = this.dialogService.open(this.resultDialog, { context: imgUrl });
  }

  close() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.serviceSubscription = this.service.serviceSubject
      .subscribe((action: any) => {
        switch (action.type) {
          case "open":
            this.open(action.url)
            break;
          case "close":
            this.close()
          default:
            break;
        }
      })
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    this.serviceSubscription.unsubscribe();
  }

}
