import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { NbIconLibraries } from '@nebular/theme';
import { SpinnerService } from '../../../@core/services/spinner.service';
import { MyTranslateService } from '../../../@core/services/translate.service';
import { PriceRatesService } from '../../../@core/services/price-rates.service';


@Component({
  selector: 'ngx-no-columns',
  templateUrl: './no-columns.component.html',
  styleUrls: ['./no-columns.component.scss']
})
export class NoColumnsComponent implements OnInit {

  showSpinner = false;

  userIsLogged = false;
  constructor(
    private authService: AuthService, 
    iconsLibrary: NbIconLibraries, 
    private spinnerService: SpinnerService,
    private languageService : MyTranslateService,
    private pricesRatesService : PriceRatesService) {
    iconsLibrary.registerFontPack('sw', { packClass: 'sw', iconClassPrefix: 'sw' });
  }

  onLangSelectChange(e) {
    this.languageService.translate.use(e);
  }

  onPriceRatesButtonClick() {
    this.pricesRatesService.open();
  }

  ngOnInit(): void {
    this.authService.isLogged()
      .subscribe((result: any) => {
        if (result)
          this.userIsLogged = true;
      })
    this.spinnerService.spinnerSubject
      .subscribe((action:any) => {
        if (action.show)
          this.showSpinner = true;
        else
          this.showSpinner = false;
      })
  }

}
