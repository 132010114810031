import { Component, OnInit, ViewChild } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ResultDialogService } from '../../services/result-dialog.service';

@Component({
  selector: 'ngx-result-dialog',
  templateUrl: './result-dialog.component.html',
  styleUrls: ['./result-dialog.component.scss']
})
export class ResultDialogComponent implements OnInit {

  resultDialogSubscription;
  dialogRef
  @ViewChild('resultDialog') resultDialog;

  constructor(private dialogService: NbDialogService, private resultDialogService: ResultDialogService) { }

  open(action) {
    this.dialogRef = this.dialogService.open(this.resultDialog, { context: action });
  }

  onCloseButton() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.resultDialogSubscription = this.resultDialogService.serviceSubject
      .subscribe((action: any) => {
          this.open(action)
      })
  }

  ngOnDestroy() {
    this.resultDialogSubscription.unsubscribe();
  }

}
