import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { IsLoggedResolver } from './@core/guards/logged.resolve';
import { IsNotLoggedResolver } from './@core/guards/notLogged.resolve';
import { NotActiveSectionGuard } from './@core/guards/not-active-section.guard';


export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: NbLoginComponent,
      },
      {
        path: 'login',
        component: NbLoginComponent,
      },
      {
        path: 'register',
        component: NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
    ],
  },
  { path: '', redirectTo: 'panel', pathMatch: 'full' },
  { path: 'panel', resolve: [IsLoggedResolver], loadChildren: () => import('./panel/panel.module').then(m => m.PanelModule) },
  { path: 'profile-management', resolve: [IsLoggedResolver], loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule) },
  { path: 'contents-management', resolve: [IsLoggedResolver], canActivate : [NotActiveSectionGuard], loadChildren: () => import('./contents/contents.module').then(m => m.ContentsModule) },
  { path: 'tickets-management', resolve: [IsLoggedResolver], canActivate : [NotActiveSectionGuard], loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule) },
  { path: 'museums-management', resolve: [IsLoggedResolver], loadChildren: () => import('./museum-and-art-gallery/museum-and-art-gallery.module').then(m => m.MuseumAndArtGalleryModule) },
  { path: 'business-auth', resolve: [IsNotLoggedResolver], loadChildren: () => import('./business-auth/business-auth.module').then(m => m.BusinessAuthModule) },
  { path: 'business-with-us', loadChildren: () => import('./start-page/start-page.module').then(m => m.StartPageModule) },
  { path: 'beaches-and-pools', resolve: [IsLoggedResolver], loadChildren: () => import('./beaches-and-pools/beaches-and-pools.module').then(m => m.BeachesAndPoolsModule) },
  { path: 'gardens-and-parks', resolve: [IsLoggedResolver], loadChildren: () => import('./gardens-and-parks/gardens-and-parks.module').then(m => m.GardensAndParksModule) },
  { path: '**', redirectTo: 'panel' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
