<ng-template #resultDialog let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-body>
            <div id="price-rates-table-container" style="max-height: 500px;overflow-y: auto;">
                <div id="sworld-business-tariffe_28918" align=center x:publishsource="Excel">

                    <table border=0 cellpadding=0 cellspacing=0 width=683 style='border-collapse:
                 collapse;table-layout:fixed;width:512pt'>
                        <col class=xl6828918 width=320 style='mso-width-source:userset;mso-width-alt:
                 11377;width:240pt'>
                        <col class=xl6828918 width=363 style='mso-width-source:userset;mso-width-alt:
                 12913;width:272pt'>
                        <tr height=24 style='height:18.0pt'>
                            <td colspan=2 height=24 class=xl6428918 width=683 style='height:18.0pt;
                  width:512pt'>{{'PRICE_RATES.TITLE' | translate}}:</td>
                        </tr>
                        <tr height=24 style='height:18.0pt'>
                            <td height=24 class=xl6428918 style='height:18.0pt'>&nbsp;</td>
                            <td class=xl6428918>&nbsp;</td>
                        </tr>
                        <tr height=38 style='mso-height-source:userset;height:28.2pt'>
                            <td height=38 class=xl6428918 style='height:28.2pt'>{{'PRICE_RATES.TICKETS' | translate}}</td>
                            <td class=xl6428918>{{'PRICE_RATES.FEE' | translate}}</td>
                        </tr>
                        <tr height=50 style='mso-height-source:userset;height:37.2pt'>
                            <td height=50 class=xl6528918 style='height:37.2pt'>{{'PRICE_RATES.FROM' | translate}} 1 {{'PRICE_RATES.TO' | translate}} 10 €</td>
                            <td class=xl6628918>0,50 €</td>
                        </tr>
                        <tr height=54 style='mso-height-source:userset;height:40.8pt'>
                            <td height=54 class=xl6528918 style='height:40.8pt'>{{'PRICE_RATES.OVER' | translate}} 10 €</td>
                            <td class=xl6728918>10%</td>
                        </tr>
                        <tr height=54 style='mso-height-source:userset;height:40.8pt'>
                            <td height=54 class=xl6428918 style='height:40.8pt'>{{'PRICE_RATES.GUIDES_TRAVEL_ARTICLES' | translate}}</td>
                            <td class=xl6428918>{{'PRICE_RATES.FEE' | translate}}</td>
                        </tr>
                        <tr height=44 style='mso-height-source:userset;height:33.0pt'>
                            <td height=44 class=xl6528918 style='height:33.0pt'>{{'PRICE_RATES.FROM' | translate}} 1 {{'PRICE_RATES.TO' | translate}} 3 €</td>
                            <td class=xl6628918>0,10 €</td>
                        </tr>
                        <tr height=50 style='mso-height-source:userset;height:37.2pt'>
                            <td height=50 class=xl6528918 style='height:37.2pt'>{{'PRICE_RATES.OVER' | translate}} 3 €</td>
                            <td class=xl6628918>0,15 €</td>
                        </tr>
                        <tr height=66 style='mso-height-source:userset;height:49.2pt'>
                            <td colspan=2 height=66 class=xl6428918 style='height:49.2pt'>{{'PRICE_RATES.ACCREDITATION PROCEDURE' | translate}}:</td>
                        </tr>
                        <tr height=59 style='mso-height-source:userset;height:44.4pt'>
                            <td colspan=2 height=59 class=xl7028918 width=683 style='height:44.4pt;
                  width:512pt'>{{'PRICE_RATES.TEXT_2' | translate}}:</td>
                        </tr>
                        <tr height=66 style='mso-height-source:userset;height:49.2pt'>
                            <td height=66 class=xl6328918 style='height:49.2pt'>&nbsp;</td>
                            <td class=xl6328918>&nbsp;</td>
                        </tr>
                        <tr height=58 style='mso-height-source:userset;height:43.8pt'>
                            <td height=58 class=xl6428918 style='height:43.8pt'>{{'PRICE_RATES.PAYMENT_METHOD' | translate}}</td>
                            <td class=xl6428918>{{'PRICE_RATES.ACCREDITATION_TIMES' | translate}}</td>
                        </tr>
                        <tr height=38 style='mso-height-source:userset;height:28.8pt'>
                            <td height=38 class=xl6528918 style='height:28.8pt'>Paypal</td>
                            <td class=xl6628918>{{'PRICE_RATES.INSTANT' | translate}}</td>
                        </tr>
                        <tr height=54 style='mso-height-source:userset;height:40.8pt'>
                            <td height=54 class=xl6528918 style='height:40.8pt'>{{'PRICE_RATES.BANK_TRANSFER' | translate}}*</td>
                            <td class=xl6728918>2-3 {{'PRICE_RATES.WORKING_DAYS' | translate}}</td>
                        </tr>
                        <tr height=55 style='mso-height-source:userset;height:41.4pt'>
                            <td colspan=2 height=55 class=xl7028918 width=683 style='height:41.4pt;
                  width:512pt'>* {{'PRICE_RATES.TEXT_3' | translate}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <button style="margin-top: 15px;" (click)="close()" nbButton>{{'PRICE_RATES.CLOSE' | translate}}</button>
        </nb-card-body>
    </nb-card>
</ng-template>