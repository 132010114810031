import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { getShop, Sale, Ticket } from '../../panel/models/get-shop';
import { NbToastrService } from '@nebular/theme';
import { AuthService } from './auth/auth.service';
import { ResultDialogService } from './result-dialog.service';
declare var _;

@Injectable()
export class ApiService {

  constructor(private httpClient: HttpClient, private toastService: ResultDialogService, private authservice: AuthService) { }

  transformToSalesTotal(data: getShop) {
    let placeSales = data.sales.filter((sale: Sale) => sale.id_type_cont == '01');
    let ticketsSales = data.sales.filter((sale: Sale) => sale.id_type_cont == '04');
    let totalPlaceSales = 0;
    let totalTicketsSales = 0;
    let placesOnSale = data.articles;
    let ticketsOnSale = data.tickets;
    placeSales.map((sale) => totalPlaceSales += parseInt(sale.total));
    ticketsSales.map((sale) => totalTicketsSales += parseInt(sale.total));
    return { placeSales, ticketsSales, placesOnSale, ticketsOnSale, totalPlaceSales, totalTicketsSales };
  }

  transformToEventSalesTotal(data: getShop, contentId) {
    let ticketsSales = data.sales.filter((sale: Sale) => sale.id_type_cont == '04' && sale.id_cont == contentId);
    let totalTicketsSales = 0;
    let ticketsOnSale = data.tickets.filter((ticket: Ticket) => ticket.id_type_cont == '04' && ticket.id_articolo == contentId);
    ticketsSales.map((sale) => totalTicketsSales += parseInt(sale.total));
    return { ticketsSales, ticketsOnSale, totalTicketsSales };
  }

  transformToTicketSalesTotal(totalEventData, contentId, ticketId) {
    let data = this.transformToEventSalesTotal(totalEventData, contentId);
    let ticketsSales: any = []
    let totalTicketSales = 0;
    data.ticketsSales.map((sale, i) => {
      let ticketList = sale.ticketList.filter(ticket => ticket.ticketid == ticketId)
      if (ticketList.length > 0) {
        totalTicketSales += ticketList[0].subtotal;
        data.ticketsSales[i].ticketList = ticketList;
        ticketsSales.push({ data: Object.assign(data.ticketsSales[i], data.ticketsSales[i].ticketList[0]) });
      }
    });
    let ticketDetail = data.ticketsOnSale.find(ticket => ticket.id_ticket == ticketId);
    return { ticketsSales, ticketDetail, totalTicketSales };
  }

  getProfile() {
    return this.httpClient.get(environment.api.getBusiness);
  }

  getUserShopTIcketSaleDetail(saleId) {
    let userId = this.authservice.userId;
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authservice.token);
    let formData = new FormData;
    formData.append('userID', userId.toString());
    return this.httpClient.post(environment.api.baseUrl + environment.api.getShop, formData, { headers })
      .pipe(map((userShop: any) => {
        return _.find(userShop.sales, (o) => o.salesID == saleId)
      }))
  }

  getUserShopContents(userId = 257) {
    userId = this.authservice.userId;
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authservice.token);
    let formData = new FormData;
    formData.append('userID', userId.toString());
    return this.httpClient.post(environment.api.baseUrl + environment.api.getShop, formData, { headers })
      .pipe(
        catchError(err => {
          this.toastService.danger('Errore API', 'API_ERROR');
          return err;
        }),
        map((data: any) => {
          return this.transformToSalesTotal(data);
        })
      )
  }

  getUserShopEventDetail(contentId, userId = 257) {
    userId = this.authservice.userId;
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authservice.token);
    let formData = new FormData;
    formData.append('userID', userId.toString());
    return this.httpClient.post(environment.api.baseUrl + environment.api.getShop, formData, { headers })
      .pipe(
        map((data: any) => {
          return this.transformToEventSalesTotal(data, contentId);
        })
      )
  }

  getUserShopTicketDetail(contentId, ticketId, userId = 257) {
    userId = this.authservice.userId;
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authservice.token);
    let formData = new FormData;
    formData.append('userID', userId.toString());
    return this.httpClient.post(environment.api.baseUrl + environment.api.getShop, formData, { headers })
      .pipe(
        map((data: any) => {
          return this.transformToTicketSalesTotal(data, contentId, ticketId);
        })
      )
  }

  getUc(userId = 257, firstName = "Michele", lastName = "de Falco", startIndex = 0, listviewPageDimension = 30 ) {
    userId = this.authservice.userId;
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authservice.token);
    return this.httpClient.get(environment.api.baseUrl + environment.api.getUc + '?payload=' + JSON.stringify(
      { userData: [{ oid: userId, firstName: firstName, lastName: lastName, startIndex : startIndex, listviewPageDimension : listviewPageDimension }] }
    ),
      { headers })
      .pipe(
        catchError(err => {
          this.toastService.danger('Errore API', 'API_ERROR');
          return err;
        }),
      )
  }

  getContent(contentId, contentType, contentDistance = 0) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authservice.token);
    console.log(headers);
    let formData = new FormData;
    formData.append('payload', JSON.stringify(
      {
        sid: this.authservice.token,
        type: 'zoom',
        dataContentList: [
          { contentId: contentId, contentType: contentType, contentDistance : contentDistance }
        ]
      }
    )
    )
    return this.httpClient.post(environment.api.baseUrl + environment.api.getContent, formData, { headers })
      .pipe(
        catchError(err => {
          this.toastService.danger('Errore API', 'API_ERROR');
          return err;
        }),
      )
  }

  uploadImage(image, contentType) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authservice.token);
    let formData = new FormData;
    formData.append("file", image);
    formData.append("contentType", contentType);
    return this.httpClient.post(environment.api.baseUrl + environment.api.uploadImage, formData, { headers });
  }

  deleteUploadedImage(imageName) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authservice.token);
    let formData = new FormData;
    formData.append("payload", JSON.stringify({ "idFile": "" + imageName + "", "contentId": "null", "contentType": "event", "context": "upload", "operation": "add", "sortableArea": "#eventSortableArea", sid : "" }));
    return this.httpClient.post(environment.api.baseUrl + environment.api.deleteUploadedImage, formData, { headers });
  }

  getSellPriceTax(currency, total, articleType) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authservice.token);
    return this.httpClient.get(environment.api.baseUrl + "SworldApi/SworldEcommerceApi/loadtax.php?" +
      "from=" + currency +
      "&a=" + total +
      "&type=" + articleType)
  }

  verifyVat(vat, country) {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authservice.token);
    let formData = new FormData;
    formData.append('data', JSON.stringify({bvat : vat, bstate : country}));
    return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/abusiness/verifyVat/index.php", formData, {headers});
  }
}
