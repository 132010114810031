import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  spinnerSubject = new Subject();

  constructor() { }

  show() {
    this.spinnerSubject.next({ show: true })
  }
  hide() {
    this.spinnerSubject.next({ show: false })
  }
}
