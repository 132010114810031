import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { MyTranslateService } from '../../../@core/services/translate.service';
import { PriceRatesService } from '../../../@core/services/price-rates.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  private contextMenuSub;
  userPictureOnly: boolean = false;
  user: any;
  generalUser : any;
  generalUserName = "";

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private router : Router,
              private authService: AuthService,
              public languageService : MyTranslateService,
              private pricesRatesService : PriceRatesService) {
  }

  onLangSelectChange(e) {
    this.languageService.translate.use(e);
  }

  onPriceRatesButtonClick() {
    this.pricesRatesService.open();
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.contextMenuSub = this.menuService.onItemClick()
    .subscribe(e => {
      if(e.tag === 'headerProfileContextMenu') {
        switch (e.item.title) {
          case "Profile":
            this.router.navigate(['/profile-management/business-info']);
            break;
          case "Log out":
            this.userService.logOut();
            break;
          default:
            break;
        }
      }
    })

    this.userService.getBusiness()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user:any) => {
        this.user = user.getBusiness
        this.user.icon = user.getBusiness.icon + "?t=" + Date.now();
      });

    this.authService.getSocial()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user:any) => {
        this.generalUser = user
        this.generalUserName = this.generalUser.first_name + " " + this.generalUser.last_name;
        this.generalUser.main_photo = user.main_photo + "?t=" + Date.now();
      });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
