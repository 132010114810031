import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PriceRatesService {

  serviceSubject = new Subject();

  constructor() { }

  open() {
    this.serviceSubject.next('open')
  }

  close() {
    this.serviceSubject.next('close')
  }
}
