import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { ResultDialogService } from '../services/result-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class NotActiveSectionGuard implements CanActivate {
  constructor(private toastService: ResultDialogService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.toastService.info("Coming Soon!")
    return false;
  }

}
