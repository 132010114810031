import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, EMPTY, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class IsLoggedResolver implements Resolve<any> {

    constructor(private authService: AuthService, private router : Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
        return this.authService.isLogged()
            .pipe(
                map(result => {
                    if (result)
                        return of(result)
                    this.router.navigate(['/business-with-us'])
                    return EMPTY
                })
            )
    }


}