<nb-layout windowMode>
    <nb-layout-header fixed>
        <ngx-header></ngx-header>
    </nb-layout-header>

    <nb-sidebar *ngIf="userIsLogged" class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>

        <div id="sidebar-lang-select" style="margin-top: 30px;">
            <nb-select (selectedChange)="onLangSelectChange($event)" [selected]="languageService.translate.currentLang">
                <nb-option value="it">Italiano</nb-option>
                <nb-option value="en">English</nb-option>
                <nb-option value="de">Deutsche</nb-option>
            </nb-select>
        </div>
        <div style="margin-top: 30px;" id="price-rates-button">
            <button (click)="onPriceRatesButtonClick()" nbButton>{{'PRICE_RATES.BUTTON_OPEN' | translate}}</button>
        </div>
        <div class="sidebar-info" style="
        height: 100px;
        background: #173b4d;
        margin-top: 116px;
        text-align: center;
        position: relative;
        padding-top: 30px;">
            <img style="
            width: 80px;
            position: absolute;
            top: -49px;
            right: 64px;
            " _ngcontent-gyg-c249="" src="/assets/images/kate.png" class="border border-primary rounded-circle">
            <p style="
            color: #fff;
            line-height: 2;
            ">
                {{'SIDEBAR_INFO.TEXT' | translate}} <a href="mailto:business@sworld.co.uk">business@sworld.co.uk</a>
            </p>
        </div>
    </nb-sidebar>

    <nb-layout-column [nbSpinner]="showSpinner" nbSpinnerStatus="success" nbSpinnerSize="giant" style="
    background-image: url('/assets/images/imagesStartPage/header_public_entities(2).jpg');
    background-repeat: no-repeat;
    background-size: 100%;">
        <ng-content select="router-outlet"></ng-content>
    </nb-layout-column>

    <!-- <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
    </nb-layout-footer> -->
</nb-layout>