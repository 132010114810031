import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CreateItem } from '../../../panel/models/create-item';
import { InsertDt, Bh, Bh0 } from '../../../panel/models/insert-dt';
import { map, concatMap, catchError } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class EventService {

    token = window.localStorage.getItem('token');
    constructor(private httpClient: HttpClient, private authService: AuthService) { }

    getDt(contentId, contentType) {
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.token);
        let formData = new FormData;
        formData.append('get_dt_req', JSON.stringify({ id_cont: contentId, contentType: contentType }))
        return this.httpClient.post(environment.api.baseUrl + environment.api.getDt, formData, { headers });
    }

    getBh(contentId, contentType) {
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.token);
        let formData = new FormData;
        formData.append('get_bh_req', JSON.stringify({ id_cont: contentId, contentType: contentType }))
        return this.httpClient.post(environment.api.baseUrl + environment.api.getBh, formData, { headers });
    }

    create(data: any, eventCategoryId = 56) {
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.token);
        let eventCreateObject: any = Object.assign({}, {
            mediaToAdd: data.mediaToAdd,
            contentType: "04",
            place: data.eventAddress.address_components,
            data: [{
                contentId: "",
                sw_sid: this.authService.token,
                sw_context: "",
                sw_content_language: data.eventLang,
                sw_content_title: data.eventTitle,
                sw_content_category: eventCategoryId,
                sw_content_brief_description: "",
                sw_content_description: data.eventDesc,
                sw_content_tags: data.eventTags,
                sw_address_latitude: data.eventAddress.geometry.location.lat(),
                sw_address_longitude: data.eventAddress.geometry.location.lng(),
                sw_formatted_address: data.eventAddress.formatted_address,
                sw_place_id: data.eventAddress.place_id,
                sw_content_datetime: "",
                sw_eventFromDateTime: "",
                sw_eventToDateTime: "",
                sw_isFree: "1000000004000000"
            }]
        });
        let formData = new FormData;
        formData.append("payload", JSON.stringify(eventCreateObject));
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/places/addContent/index.php", formData, { headers })
            .pipe(
                catchError(err => of(err)),
                map((eventInsertResult: any) => {
                    if (eventInsertResult.success)
                        return eventInsertResult.contentId
                    else
                        return { error: true }
                }),
                concatMap((eventInsertResult: any) => {
                    if (eventInsertResult.error)
                        return of(eventInsertResult);
                    else {
                        return this.insertDt(eventInsertResult, data)
                            .pipe(
                                catchError(err => of(err)),
                                map((insertDtResult: any) => {
                                    if (insertDtResult.status)
                                        return { eventInsertResult, insertDtResult }
                                    else
                                        return { eventInsertResult, insertDtResult: { error: true } }
                                })
                            )
                    }
                })
            )
    }

    edit(data: any, eventCategoryId = 56) {
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.token);
        let eventEditObject: any = Object.assign({}, {
            sw_eventMedia: data.mediaToAdd,
            contentType: "04",
            place: data.place,
            contentId: data.contentId,
            data: [{
                contentId: data.contentId,
                sw_sid: this.authService.token,
                sw_context: "",
                sw_eventLanguage: data.contentLanguageId,
                sw_eventTitle: data.contentTitle,
                sw_eventCategory: data.contentCategoryId,
                sw_content_brief_description: "",
                sw_eventDescription: data.contentDescription,
                sw_eventTags: data.contentTags,
                sw_event_latitude: data.contentLat,
                sw_event_longitude: data.contentLng,
                sw_formatted_address: data.contentFormattedAddress,
                sw_place_id: data.contentId,
                sw_content_datetime: "",
                sw_eventFromDateTime: "",
                sw_eventToDateTime: "",
                sw_isFree: ""
            }]
        });
        let formData = new FormData;
        formData.append("payload", JSON.stringify(eventEditObject));
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/places/editContent/index.php", formData, { headers })
    }

    editImage(data) {
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.token);
        let formData = new FormData;
        formData.append("payload", JSON.stringify(data));
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/places/editContent/index.php", formData, { headers })
    }

    delete(eventId) {
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.token);
        let formData = new FormData;
        formData.append("data", JSON.stringify({ sid: "", idToDelete: eventId, contentType: "04", admin: 1 }));
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/places/todelete/index.php", formData, { headers })
    }

    createTicket(data: any) {
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.token);
        let formData = new FormData;
        let ticketCreateObject = Object.assign({}, {
            timestamp: "",
            id_cont: data.ticketData.id_cont,
            contentType: "04",
            bh: {
                bh0: Object.assign({}, {
                    pots: data.ticketDatesAndTime.pots,
                    dows: data.ticketDatesAndTime.dows,
                    hts: "",
                    info: "",
                    tickets: Object.assign({}, {
                        ticket0: Object.assign({}, {
                            title: data.ticketData.ticketTitle,
                            description: data.ticketData.ticketDescription,
                            currency: data.ticketData.ticketPriceCurrency,
                            price: data.ticketData.ticketPrice,
                            stock: data.ticketData.ticketStock,
                            dailyQuantity : data.ticketData.ticketIsDailyQuantity,
                            ticketType: data.ticketData.ticketCategory,
                            ticketsInfo1: data.ticketData.ticketInfo,
                            ticketsInfo2 : "",
                            ticketsInfo3 : "",
                            ticketsInfo4 : ""
                        })
                    })
                })
            }
        })
        formData.append('new_sell_item', JSON.stringify(ticketCreateObject));
        return this.httpClient.post(environment.api.baseUrl + environment.api.insertBh, formData, { headers });
    }

    deleteTicket(idTicket) {
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.token);
        let formData = new FormData;
        formData.append("payload", JSON.stringify({ idTicket: idTicket }));
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/abusiness/deleteTicket/index.php", formData, { headers })
    }

    insertDt(id_cont, bh) {
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.authService.token);
        let formData = new FormData;
        let bh0: Bh0 = Object.assign({}, {
            pots: bh.eventPots,
            dows: bh.eventDows,
            hts: "",
            info : ""
        })
        let bhData: Bh = Object.assign({}, {
            bh0: bh0
        })
        let insertDtCreateObject: InsertDt = Object.assign({},
            {
                timestamp: "",
                id_cont: id_cont,
                contentType: "04",
                bh: bhData
            });
        formData.append("new_dateTime", JSON.stringify(insertDtCreateObject));
        return this.httpClient.post(environment.api.baseUrl + "SworldApi/SworldEcommerceApi/api.php?o=insert_dt", formData, { headers });
    }
}