import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { map, catchError, concatMap } from 'rxjs/operators';
import { NbToastrService } from '@nebular/theme';
import * as jwt_decode from 'jwt-decode';
import { ResultDialogService } from '../result-dialog.service';

@Injectable()
export class AuthService {
    token = window.localStorage.getItem(environment.api.localStorageTokenKey);
    decodedToken;
    userId;
    constructor(private httpClient: HttpClient, private toastService: ResultDialogService) {
        try {
            this.decodedToken = jwt_decode(window.localStorage.getItem(environment.api.localStorageTokenKey));
            this.userId = this.decodedToken.JWT_OID;
        } catch (error) {
            console.log('token is missing')
        }
    }

    login(email, password) {
        let formData = new FormData;
        formData.append("email", email);
        formData.append("password", password);
        formData.append("isBusiness", "1");
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/auth/login/index.php", formData, { headers })
            .pipe(
                catchError(err => {
                    this.toastService.danger('Errore API', 'API_ERROR');
                    return err;
                })
            )
    }

    isLogged() {
        let formData = new FormData;
        formData.append("session", "true");
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/auth/login/index.php", formData, { headers })
            .pipe(
                catchError(err => {
                    this.toastService.danger('Errore API', 'API_ERROR');
                    return err;
                }),
                map((result: any) => {
                    if (result.oid > 0)
                        return true;
                    return false;
                })
            )
    }

    resetPassword(newPass, rePass) {
        let formData = new FormData;
        formData.append("data", JSON.stringify({ pass: newPass, repass: rePass }));
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/abusiness/resetPassword/index.php", formData, { headers })
    }

    getBusiness() {
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/abusiness/getbusiness/index.php", {}, { headers })
    }

    getSocial() {
        let formData = new FormData;
        formData.append("session", "true");
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/auth/login/index.php", formData, { headers })
            .pipe(
                concatMap((isLoggedResult: any) => {
                    if (isLoggedResult.oid > 0) {
                        let body = JSON.stringify({ userId: isLoggedResult.oid })
                        return this.httpClient.post(environment.api.baseUrl + "SworldApi/SworldSocialApi/api.php?o=getSocial", body)
                            .pipe(
                                map((userSocial: any) => {
                                    const keys = ['email', 'first_name', 'last_name', 'gender', 'birthday', 'telephone', 'main_photo'];
                                    let userSocialFiltered = {};
                                    Object.keys(userSocial.profile).forEach(key => {
                                        if (keys.includes(key))
                                            Object.assign(userSocialFiltered, { [key]: userSocial.profile[key].value });
                                    });
                                    return userSocialFiltered;
                                })
                            )
                    }
                })
            )
    }

    updateGeneralProfile(profileData) {
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
        const formData = new FormData;
        formData.append('data', JSON.stringify(profileData));
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/abusiness/generalInfo/index.php", formData, { headers })
    }

    updateBusinessProfile(profileData) {
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
        const formData = new FormData;
        formData.append('data', JSON.stringify(profileData));
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/abusiness/businessInfo/index.php", formData, { headers })
    }

    getSubUsersList() {
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/abusiness/getSubuser/index.php", {}, { headers })
    }

    createSubUser(subUserData) {
        let headers = new HttpHeaders()
            .set('Authorization', 'Bearer ' + this.token)
            .set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
        const payload = new HttpParams()
            .set('data', JSON.stringify(subUserData));
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/abusiness/addSubuser/index.php", payload, { headers })
    }

    editSubUser(subUserData) {
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
        const formData = new FormData;
        formData.append('data', JSON.stringify(subUserData));
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/abusiness/editSubUser/index.php", formData, { headers })
    }

    deleteSubUser(subUserData) {
        let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
        const formData = new FormData;
        formData.append('data', JSON.stringify(subUserData));
        return this.httpClient.post(environment.api.baseUrl + "SworldWebApi/api/abusiness/deleteSubUser/index.php", formData, { headers })
    }
}