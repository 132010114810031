<ng-template #resultDialog let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-body>
            <h6 style="color: #fff;">{{ data.message }}</h6>

        </nb-card-body>
        <nb-card-footer style="
        display: flex;
        flex-direction: column;
        align-items: center;">
            <ng-template [ngIf]="data.type == 'success'">
                <nb-icon style="color: green;font-size: 70px;" icon="checkmark-circle-2-outline"></nb-icon>
            </ng-template>
            <ng-template [ngIf]="data.type == 'danger'">
                <nb-icon style="color: red;font-size: 70px;" icon="close-circle-outline"></nb-icon>
            </ng-template>
            <ng-template [ngIf]="data.type == 'info'">
                <nb-icon style="color: yellow;font-size: 70px;" icon="alert-circle-outline"></nb-icon>
            </ng-template>
            <button nbButton (click)="onCloseButton()">OK</button>
        </nb-card-footer>
    </nb-card>
</ng-template>