import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageZoomService {

  serviceSubject = new Subject();

  constructor() { }

  open(url) {
    this.serviceSubject.next({ type: 'open', url: url })
  }

  close() {
    this.serviceSubject.next({ type: 'close' })
  }
}
